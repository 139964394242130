<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-for="admissionRoundSelect">
                      <v-select
                        v-model="admissionRoundSelect"
                        :options="admissionRounds"
                        :reduce="option => option.value"
                        placeholder="1. Đợt xét tuyển"
                        @input="onChangeAdmissionRound"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label-for="admissionFormSelect">
                      <v-select
                        v-model="admissionFormSelect"
                        :options="dataListAdmissionForm"
                        :reduce="option => option.value"
                        placeholder="2. Phương thức xét tuyển"
                        @input="onChangeAdmissionForm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      title="3. Dự kiến thi"
                      label-for="admissionExaminationSchedulerSelect"
                    >
                      <v-select
                        v-model="admissionExaminationSchedulerSelect"
                        vid="admissionExaminationSchedulerSelect"
                        :options="admissionExaminationSchedulers"
                        :reduce="(option) => option.value"
                        placeholder="3. Dự kiến thi"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      title="4. Môn thi"
                      label-for="admissionSubjectSelect"
                    >
                      <v-select
                        v-model="admissionSubjectSelect"
                        :options="admissionSubjectOptions"
                        :reduce="(option) => option.value"
                        placeholder="4. Môn thi"
                        @input="onChangeAdmissionSubject"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="4"
                md="3"
              >
                <b-form-group
                  title="Ngày bắt đầu"
                  label-for="startDate"
                >
                  <b-form-datepicker
                    id="startDate"
                    v-model="startDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="vi"
                    format="dd/mm/yyyy"
                    placeholder="Ngày bắt đầu"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="2"
              >
                <b-form-group
                  title="Số lượng phòng"
                >
                  <b-form-input
                    :disabled="isCountStudent == 0 || isExistExaminationRoom > 0 || !creatable"
                    v-model="countRoom"
                    :step="1"
                    min="1"
                    type="number"
                    class="text-bold text-danger"
                    placeholder="Số lượng phòng"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="7"
              >
                <div class="text-right">
                  <b-button
                    v-show="isCountStudent > 0 && isExistExaminationRoom === 0 && countRoom > 0 && creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onCreateExaminationRoom"
                  >
                    <span class="text-nowrap text-right">Tạo phòng thi</span>
                  </b-button>
                  <b-button
                    v-show="isExistExaminationRoom !== 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="onSelectExaminationRoom"
                  >
                    <span class="text-nowrap text-right">
                      Danh sách phòng
                    </span>
                  </b-button>
                  <b-dropdown
                    v-show="admissionExaminationRooms.length > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    text="Xuất PDF"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item @click="exportPdfStudentsExaminationRoom">
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In DS phòng thi
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportPdfStudentsExaminationScoreFile">
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In phiếu ghi điểm SBD
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="admissionExaminationRooms"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  line-numbers
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.examinationRoomSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onEditExaminationRoom(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          right
                          variant="success"
                        >
                          <b-dropdown-item
                            v-if="studentExaminationRoomReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowsStudentExaminationRoom(props.row)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Danh sách học viên
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="examInvigilatorReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onAssignExamInvigilator(props.row.id)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Phân công coi thi
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="studentExaminationPointUpdatable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowStudentExaminationPoint(props.row)"
                          >
                            <feather-icon icon="EditIcon" />
                            Nhập điểm thi theo phách
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <AdmissionExaminationRoomSave
      ref="examinationRoomsSaveFormRef"
      :examination-room="currentExaminationRoom"
      @succeed="onSucceed"
    />

    <b-modal
      id="studentExaminationPointModal"
      centered
      :title="`Nhập điểm thi theo phách: ${(currentExaminationRoom)?currentExaminationRoom.name : ''}`"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
    >
      <StudentExaminationPoint
        :admission-examination-room-id="currentExaminationRoomId"
        :admission-round-id="admissionRoundSelect"
        :admission-form-id="admissionFormSelect"
        :admission-subject-id="admissionSubjectSelect"
      />
    </b-modal>
    <b-modal
      id="studentExaminationRoomModal"
      centered
      :title="`Danh sách thí sinh: ${(currentExaminationRoom)?currentExaminationRoom.name : ''}`"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
    >
      <StudentExaminationRoom
        :ext-data="currentExaminationRoom"
      />
    </b-modal>
    <ExaminationRoomTeacher
      :admission-examination-room-id="currentExaminationRoomId"
      :admission-subject-id="admissionSubjectSelect"
    />
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BFormInput,
  BRow,
  BModal, BFormDatepicker,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import AdmissionExaminationRoomSave from '@/views/admission/admission-examination-room/AdmissionExaminationRoomSave.vue'
import vSelect from 'vue-select'
import StudentExaminationPoint from '@/views/admission/applicant-student-examination-room/ApplicantStudentExaminationPoint.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import StudentExaminationRoom from '@/views/admission/applicant-student-examination-room/ApplicantStudentExaminationRoom.vue'
import ExaminationRoomTeacher from '@/views/admission/admission-examination-room/examination-room-teacher/AdmissionExaminationRoomTeacher.vue'
import { getUser } from '@/auth/utils'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'AdmissionExaminationRoom',
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ExaminationRoomTeacher,
    StudentExaminationPoint,
    StudentExaminationRoom,
    AdmissionExaminationRoomSave,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormInput,
    BModal,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      admissionRoundSelect: undefined,
      admissionFormSelect: undefined,
      admissionExaminationSchedulerSelect: undefined,
      admissionSubjectSelect: undefined,
      startDate: undefined,
      isExistExaminationRoom: 0,
      isCountStudent: 0,
      isLoading: false,
      countRoom: 1,
      filter: {
        admissionRoundId: getUser().admissionRoundId,
        admissionFormId: null,
        currentPage: 1,
        itemsPerPage: 10,
        code: null,
        name: null,
        admissionSubjectId: null,
        status: null,
        sort: null,
      },
      currentExaminationRoom: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên phòng thi theo môn thi',
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Địa điểm thi',
          field: 'room',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Tên học phần',
          field: 'admissionSubjectName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Giám thị',
          field: 'supervisorNum',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Bắt đầu',
          field: 'startAt',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Kết thúc',
          field: 'endAt',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      currentExaminationRoomId: null,
    }
  },
  computed: {
    ...mapGetters({
      admissionExaminationRooms: 'admissionExaminationRoom/examinationRooms',
      totalRows: 'admissionExaminationRoom/totalRows',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionExaminationRoom/admissionForms',
      listAdmissionSubject: 'admissionExaminationRoom/listAdmissionSubject',
      admissionExaminationSchedulers: 'admissionExaminationScheduler/admissionExaminationSchedulers',
      admissionExaminationSchedulerSubjects: 'admissionExaminationSchedulerSubject/admissionExaminationSchedulerSubjects',
      admissionExaminationSchedulerSubjectDates: 'admissionExaminationSchedulerSubject/admissionExaminationSchedulerSubjectDates',
    }),
    admissionSubjectOptions() {
      return this.listAdmissionSubject.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    studentExaminationPointUpdatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_EXAMINATION_POINT)
    },
    examInvigilatorReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.ADMISSION_EXAMINATION_ROOM_TEACHER)
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ADMISSION_EXAMINATION_ROOM)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_EXAMINATION_ROOM)
    },
    studentExaminationRoomReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.APPLICANT_STUDENT_EXAMINATION_ROOM)
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getAllRooms: 'examinationRoom/getAllRooms',
      getAllSubjects: 'examinationRoom/getAllSubjects',
      readAdmissionFormByAdmissionRoundId: 'admissionExaminationRoom/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getAdmissionExaminationRooms: 'admissionExaminationRoom/getAdmissionExaminationRooms',
      deleteAdmissionExaminationRoom: 'admissionExaminationRoom/deleteAdmissionExaminationRoom',
      getAdmissionSubject: 'admissionExaminationRoom/getAdmissionSubject',
      insertAdmissionExaminationRooms: 'admissionExaminationRoom/insertAdmissionExaminationRooms',
      getAdmissionExaminationSchedulers: 'admissionExaminationScheduler/getAdmissionExaminationSchedulers',
      getAdmissionExaminationSchedulerSubjects: 'admissionExaminationSchedulerSubject/getAdmissionExaminationSchedulerSubjects',
      exportStudentsExaminationRoom: 'admissionExaminationRoom/exportPdf',
      exportPdfStudentsExaminationScore: 'applicantStudentExaminationRoom/exportPdfStudentsExaminationScore',
    }),
    async onChangeAdmissionRound(id) {
      if (this.admissionRoundSelect == null) {
        this.reset()
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_FORMS', {
          data: [],
        })
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_SUBJECT', {
          data: [],
        })
        this.admissionFormSelect = null
        this.admissionSubjectSelect = null
      } else {
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
      }
    },
    async onChangeAdmissionForm() {
      this.admissionExaminationSchedulerSelect = null
      if (this.admissionFormSelect == null) {
        this.reset()
        this.$store.commit('examinationRoom/SET_ADMISSION_SUBJECT', {
          data: [],
        })
        this.admissionSubjectSelect = null
      } else {
        await Promise.all([
          this.getAdmissionExaminationSchedulers({
            admissionRoundId: this.admissionRoundSelect,
            admissionFormId: this.admissionFormSelect
          }),
          this.getAdmissionSubject({
            admissionRoundId: this.admissionRoundSelect,
            admissionFormId: this.admissionFormSelect
          })
        ])
        if (this.admissionExaminationSchedulers.length > 0) {
          this.admissionExaminationSchedulerSelect = this.admissionExaminationSchedulers[0].value
        }
      }
    },
    async onChangeAdmissionSubject() {
      let item = null

      if (this.admissionSubjectSelect == null) {
        this.reset()
      } else {
        item = this.listAdmissionSubject.find(
          x => x.id === this.admissionSubjectSelect,
        )

        this.isExistExaminationRoom = item.count_examination_room
        this.isCountStudent = item.count_student
        this.filter.admissionSubjectId = this.admissionSubjectSelect
        this.$store.commit('admissionExaminationRoom/SET_EXAMINATION_ROOMS', {
          total: 0,
          records: [],
        })

        if (this.isCountStudent === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không có thí sinh nào thi môn này!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
        // Du kien lich thi
        if (this.admissionExaminationSchedulerSelect) {
          this.startDate = undefined
          await this.getAdmissionExaminationSchedulerSubjects({
            admissionRoundId: this.admissionRoundSelect,
            admissionFormId: this.admissionFormSelect,
            admissionExaminationSchedulerId: this.admissionExaminationSchedulerSelect,
            admissionSubjectId: this.admissionSubjectSelect,
          })
          if (this.admissionExaminationSchedulerSubjectDates.length > 0) {
            this.startDate = this.admissionExaminationSchedulerSubjectDates[0].label
          }
        }
      }
    },
    reset() {
      this.isExistExaminationRoom = 0
      this.isCountStudent = 0
      this.$store.commit('admissionExaminationRoom/SET_EXAMINATION_ROOMS', {
        total: 0,
        records: [],
      })
    },
    async onSelectExaminationRoom() {
      this.isLoading = true
      try {
        await this.getAdmissionExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onCreateExaminationRoom() {
      if (!this.startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ngày bắt đầu không được trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$swal({
        title: `Bạn chắc chắn muốn tạo <span class="text-danger">${this.countRoom}</span> phòng thi môn <span class="text-danger">${this.admissionSubjectOptions.find(e => e.value === this.admissionSubjectSelect).label}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.insertAdmissionExaminationRooms({
              admissionRoundId: this.admissionRoundSelect,
              admissionFormId: this.admissionFormSelect,
              admissionSubjectId: this.admissionSubjectSelect,
              countRoom: this.countRoom,
              startDate: this.startDate,
            })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.isExistExaminationRoom = 1
                this.filter.admissionSubjectId = this.admissionSubjectSelect
                await this.onChangeAdmissionForm()
                await this.onChangeAdmissionSubject()
                await this.getAdmissionExaminationRoomsFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onEditExaminationRoom(examinationRoom) {
      const {
        rowNum, originalIndex, vgt_id, ...rest
      } = examinationRoom
      this.currentExaminationRoom = rest
    },
    onDeleteExaminationRoom(examinationRoom) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa phòng thi theo học phần <span class="text-danger">${examinationRoom.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteExaminationRoom(
              examinationRoom.id,
            )
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getAdmissionExaminationRooms(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getAdmissionExaminationRoomsFromStore()
    },
    async getAdmissionExaminationRoomsFromStore() {
      this.isLoading = true
      try {
        await this.getAdmissionExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getAdmissionExaminationRoomsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getAdmissionExaminationRoomsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'room')) this.updateParams({ room: columnFilters.room })
      if (Object.hasOwn(columnFilters, 'subject')) this.updateParams({ subject: columnFilters.subject })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getAdmissionExaminationRoomsFromStore()
    },
    async onSucceed() {
      await this.getAdmissionExaminationRoomsFromStore()
    },

    convertMysql2VnDate(rawDate) {
      const tmpDate = rawDate.split('-')
      return `${tmpDate[2]}-${tmpDate[1]}-${tmpDate[0]}`
    },
    showCreditClassList(creditClass) {
      let htmlList = '<ol class="list list-inline mb-0">'
      try {
        const tmpClasses = creditClass.split(',')
        tmpClasses.forEach(e => {
          htmlList += `<li class="list-inline-item"><span class="badge badge-success">${e}</span></li>`
        })
        htmlList += '</ol>'
      } catch (e) {
        htmlList += '<li class="list-inline-item"><span class="badge badge-dark">Không có dữ liệu</span></li>'
      }
      const htmlCountStudent = (this.isCountStudent) ? `(<u><strong class="text-danger h4">${this.isCountStudent}</strong> Thí sinh</u>)` : ''
      return `${htmlList} ${htmlCountStudent}`
    },
    onShowStudentExaminationPoint(row) {
      const {
        rowNum, originalIndex, vgt_id, ...rest
      } = row
      this.currentExaminationRoom = rest
      this.currentExaminationRoomId = rest.id
      this.$bvModal.show('studentExaminationPointModal')
    },
    onShowsStudentExaminationRoom(row) {
      const { originalIndex, vgt_id, ...rest } = row
      this.currentExaminationRoom = { ...rest }
      this.$bvModal.show('studentExaminationRoomModal')
    },
    onAssignExamInvigilator(id) {
      this.currentExaminationRoomId = id
      this.$bvModal.show('examinationRoomTeacherModal')
    },
    async exportPdfStudentsExaminationRoom() {
      if (this.admissionExaminationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportStudentsExaminationRoom({
          admissionFormId: this.admissionFormSelect,
          admissionSubjectId: this.admissionSubjectSelect,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfStudentsExaminationScoreFile() {
      if (this.admissionExaminationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdfStudentsExaminationScore({
          admissionFormId: this.admissionFormSelect,
          admissionSubjectId: this.admissionSubjectSelect,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
